import React, {useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

import {Formik} from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';

import Page from 'src/components/Page';
import {auth} from "../../Firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [fbMsg, setFbMessage]= useState("");
  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values) => {
              //TODO: validation. Or basic password check.
              const user = await auth
                .signInWithEmailAndPassword(values.email, values.password)
                .then(async(user) =>{
                  if (user) {
                    console.log(user, "@@@user@@@ -inside")
                    setFbMessage("Loggin in...")
                    const name = user?.user?.displayName;
                    let email = user?.user?.email;
                    const photoUrl = user?.user?.photoURL;
                    const emailVerified = user?.user?.emailVerified;
                    const uid = user?.user?.uid;
                    const token = await auth.currentUser.getIdToken(/* forceRefresh */ true);
                    localStorage.setItem('ivy_user_email', values.email);
                    localStorage.setItem('ivy_user_token', token);
                    localStorage.setItem('ivy_user_info',  JSON.stringify({ uid, name, email, photoUrl, emailVerified, token }));
                    navigate('/app/dashboard', {replace: true});
                  } else {
                    setFbMessage("No user found. Please try a valid login")
                    localStorage.removeItem('ivy_user_email');
                    localStorage.removeItem('ivy_user_token');
                    localStorage.removeItem('ivy_user_info');
                    navigate('/login', {replace: true});
                  }

                } )
                .catch(error => {
                  setFbMessage("No user found. Please try a valid login")
                  console.log(error, "@@@Error@@@ - auth")
                });

            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on IVY
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={3}
                >
                </Grid>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Box>
                  <p> {fbMsg}</p>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
