import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { createContext, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import {auth} from "./Firebase";

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('ivy_user_email') != null
        ? localStorage.getItem('ivy_user_email')
        : null;
  } catch (error) {
    console.log('>>>>: getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const GlobalFilterCtx = createContext({
  store: 'all',
  setStore: () => {},
  date: {},
  setDate: () => {}
});

const App = () => {
  let initDate = localStorage.getItem('ivy_date');
  initDate = typeof initDate === 'string' ? JSON.parse(initDate) : undefined;

  let initStore = localStorage.getItem('ivy_store');
  if (!initStore) {
    initStore = 'all';
    localStorage.setItem('ivy_store', 'all');
  }
  const user = getCurrentUser();
  const isLoggedIn = user ? true : false;
  const routing = useRoutes(routes(isLoggedIn));
  const [store, setStore] = useState(initStore);
  const [date, setDate] = useState(initDate);

  console.log(store, '@store@app');
  const value = {
    store,
    setStore: val => {
      setStore(val);
      localStorage.setItem('ivy_store', String(val));
    },
    date,
    setDate: val => {
      setDate(val);
      if (val) {
        localStorage.setItem('ivy_date', JSON.stringify(val));
      } else {
        localStorage.removeItem('ivy_date');
      }
    }
  };
  const [cUser, setCUser] = useState(false)
  useEffect(() => {
    const unlisten = auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        setCUser(true);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalFilterCtx.Provider value={value}>
        <GlobalStyles />
         { isLoggedIn ? cUser ? routing : <div>Loading...</div> : routing  }
      </GlobalFilterCtx.Provider>
    </ThemeProvider>
  );
};

export default App;
