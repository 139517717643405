import axios from 'axios';
import {auth} from "../Firebase";

const axiosOptions = {
  timeout: 120000,
  headers: {}
};
const axiosMobileInstance = axios.create(axiosOptions);

axiosMobileInstance.interceptors.request.use(
  async config => {

    let token;
    if (auth.currentUser) {
      token = await auth.currentUser.getIdToken(false);
    }
    config.headers['Authorization'] = `Bearer ${token}`;
    //Add store into every request
    const ivyStore = localStorage.getItem('ivy_store');
    config.headers['store'] = ivyStore;

    //Add date into every request
    const ivyDate = localStorage.getItem('ivy_date');
    if(ivyDate){
      config.headers['dateRange'] = ivyDate;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosMobileInstance;
